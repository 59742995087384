import React, { useState } from 'react';
import { Container, Box } from '@mui/material';
import { ImageList, ImageListItem, Typography } from '@mui/material';
import HeaderService from './HeaderStore';
import useStore from '../hooks/useStore';
import { useLocation } from 'react-router-dom';
import '../global.css';

const Pages = () => {
  const [fullUrl, setFullUrl] = useState('');
  const { cards } = useStore();
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const idLoja = Number(query.get('id'));

  React.useEffect(() => {
    if (typeof window !== 'undefined') {
      setFullUrl(window.location.protocol + '//' + window.location.host);
    }
  }, []);

  return (
    <>
      <Container maxWidth="false" disableGutters>
        <HeaderService />

        {cards &&
          [...cards]
            .filter((card) => card.idCard === idLoja)
            .map((card, index) => (
              <>
                <Box
                  key={index}
                  sx={{
                    maxWidth: '100vw',
                    height: '260px',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    backgroundColor: '#f1f1f1',
                    padding: '20px',
                  }}
                >
                  <Typography variant="h4" align="center">
                    {card.loja} <br /> <br />
                  </Typography>
                  <Typography variant="h5" align="center">
                    {card.descricao}
                    <br /> <br />
                  </Typography>

                  <Typography variant="h5" color="contrastText" align="center">
                    {card.fone === card.whats ? '' : 'whats ' + card.fone}
                    {card.whats === null || card.fone !== card.whats
                      ? ''
                      : 'whats ' + card.whats}
                  </Typography>

                  <Typography variant="h5" color="contrastText" align="center">
                    {card.email}
                  </Typography>

                  <Typography variant="h5" color="contrastText" align="center">
                    {card.endereco}
                  </Typography>
                </Box>

                <Container>
                  <ImageList variant="masonry" cols={3} gap={8}>
                    {Array.from({ length: 10 }, (_, index) => (
                      <ImageListItem key={index}>
                        <img
                          srcSet={`${fullUrl}/images/${card.site}/foto00${index}.jpg?w=248&fit=crop&auto=format&dpr=2 2x`}
                          src={`${fullUrl}/images/${card.site}/foto00${index}.jpg?w=248&fit=crop&auto=format`}
                          alt=""
                          loading="lazy"
                        />
                      </ImageListItem>
                    ))}
                  </ImageList>
                </Container>
              </>
            ))}
      </Container>
    </>
  );
};

export default Pages;
