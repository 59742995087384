import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import useStore from '../hooks/useStore';

import {
  Box,
  BottomNavigation,
  BottomNavigationAction,
  useMediaQuery,
} from '@mui/material';

import ConstructionOutlinedIcon from '@mui/icons-material/ConstructionOutlined';
import PhoneIphoneOutlinedIcon from '@mui/icons-material/PhoneIphoneOutlined';
import BedOutlinedIcon from '@mui/icons-material/BedOutlined';
import TimeToLeaveOutlinedIcon from '@mui/icons-material/TimeToLeaveOutlined';
import MonitorHeartOutlinedIcon from '@mui/icons-material/MonitorHeartOutlined';
import AutoFixHighOutlinedIcon from '@mui/icons-material/AutoFixHighOutlined';
import CheckroomOutlinedIcon from '@mui/icons-material/CheckroomOutlined';
import DiningOutlinedIcon from '@mui/icons-material/DiningOutlined';
import LocationSearchingIcon from '@mui/icons-material/LocationSearching';
import CottageOutlinedIcon from '@mui/icons-material/CottageOutlined';
import BusinessIcon from '@mui/icons-material/Business';

import { TextField, InputAdornment, IconButton } from '@mui/material';

const HeaderStore = () => {
  const iconMap = {
    home: <CottageOutlinedIcon />,
    beleza: <AutoFixHighOutlinedIcon />,
    comer: <DiningOutlinedIcon />,
    servicos: <ConstructionOutlinedIcon />,
    casa: <BedOutlinedIcon />,
    moda: <CheckroomOutlinedIcon />,
    eletronicos: <PhoneIphoneOutlinedIcon />,
    veiculos: <TimeToLeaveOutlinedIcon />,
    saude: <MonitorHeartOutlinedIcon />,
    imoveis: <BusinessIcon />,
  };

  const { menus } = useStore();
  const [menuValue, setMenuValue] = useState(0);
  const isMobile = useMediaQuery('(max-width:600px)');
  const [searchValue, setSearchValue] = useState('');
  const navigate = useNavigate();

  const textConvert = (text) => {
    const txt = text
      .normalize('NFD')
      .replace(/[\u0300-\u036f]/g, '')
      .replace(/[^a-zA-Z\s]/g, '')
      .split(/\s+/)
      .join('-')
      .toLowerCase();

    const newValue = '/search/?key=' + txt;
    return newValue;
  };

  const newLink = (value) => {
    navigate(value === 1 ? '/' : '/cards/?id=' + value);
    setMenuValue(value);
  };

  return (
    <Box>
      <Box>
        <Box
          display="flex"
          justifyContent="center"
          alignmenus="cenrter"
          height="60px"
          width="100%"
          sx={{
            marginTop: '330px',
            marginBottom: '10px',
          }}
        >
          <TextField
            placeholder="buscar em sorocaba"
            variant="outlined"
            value={searchValue}
            onChange={(e) => setSearchValue(e.target.value)}
            onKeyPress={(e) => {
              if (e.key === 'Enter') {
                e.preventDefault();
                navigate(textConvert(searchValue));
              }
            }}
            sx={{
              width: isMobile ? '80%' : '50%',
              backgroundColor: 'white',
              color: 'white',
              borderRadius: '10px',
              border: '3px solid #ffa468',
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    onClick={() => navigate(textConvert(searchValue))}
                  >
                    <LocationSearchingIcon />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </Box>
      </Box>
      <BottomNavigation
        value={menuValue}
        onChange={(event, newValue) => {
          setMenuValue(newValue);
        }}
        showLabels
        sx={{
          display: 'flex',
          overflowX: 'auto',
          scrollbarWidth: 'none',
          '&::-webkit-scrollbar': {
            display: 'none',
          },
          backgroundColor: 'rgba(255, 255, 255, 0.5)',
          borderBottom: '2px solid #ffffff',
          zIndex: 99,
        }}
      >
        {menus &&
          menus.map((menu) => (
            <BottomNavigationAction
              key={menu.idCat}
              label={menu.category}
              value={menu.idCat}
              icon={iconMap[menu.paths]}
              onClick={() => {
                newLink(menu.idCat);
              }}
              style={{
                color: menuValue === menu.idCat ? '#FF4E07' : '#808080',
              }}
            />
          ))}
      </BottomNavigation>
    </Box>
  );
};

export default HeaderStore;
