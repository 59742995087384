import React from 'react';
import {
  Box,
  Typography,
  Container,
  Button,
  Card,
  CardActionArea,
  CardMedia,
  CardContent,
  Skeleton,
} from '@mui/material';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import '../global.css';

import HeaderStore from './HeaderStore';
import useStore from '../hooks/useStore';
import { useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router-dom';

import ConstructionOutlinedIcon from '@mui/icons-material/ConstructionOutlined';
import PhoneIphoneOutlinedIcon from '@mui/icons-material/PhoneIphoneOutlined';
import BedOutlinedIcon from '@mui/icons-material/BedOutlined';
import TimeToLeaveOutlinedIcon from '@mui/icons-material/TimeToLeaveOutlined';
import MonitorHeartOutlinedIcon from '@mui/icons-material/MonitorHeartOutlined';
import AutoFixHighOutlinedIcon from '@mui/icons-material/AutoFixHighOutlined';
import CheckroomOutlinedIcon from '@mui/icons-material/CheckroomOutlined';
import DiningOutlinedIcon from '@mui/icons-material/DiningOutlined';
import CottageOutlinedIcon from '@mui/icons-material/CottageOutlined';
import BusinessIcon from '@mui/icons-material/Business';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

const iconMap = {
  home: <CottageOutlinedIcon />,
  beleza: <AutoFixHighOutlinedIcon />,
  comer: <DiningOutlinedIcon />,
  servicos: <ConstructionOutlinedIcon />,
  casa: <BedOutlinedIcon />,
  moda: <CheckroomOutlinedIcon />,
  eletronicos: <PhoneIphoneOutlinedIcon />,
  veiculos: <TimeToLeaveOutlinedIcon />,
  saude: <MonitorHeartOutlinedIcon />,
  imoveis: <BusinessIcon />,
};

const Cards = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const idStore = Number(query.get('id'));
  const { menus, cards } = useStore();
  let scrollRefs = {};

  const trueStore = cards && cards.find((store) => store.idCat === idStore);

  const scroll = (scrollOffset, index) => {
    scrollRefs[index].current.scrollLeft += scrollOffset;
  };

  if (menus) {
    scrollRefs = menus.map(() => React.createRef());
  }

  return (
    <Container maxWidth="false" disableGutters>
      <HeaderStore />

      {menus &&
        cards &&
        [...menus]
          .filter((storeCards) => storeCards.idCat === idStore)
          .map((storeCards, index) => (
            <Box
              key={index}
              justifyContent="center"
              alignItems="center"
              sx={{
                backgroundColor: 'rgba(241, 241, 241, 0.5)',
                padding: '20px',
                position: 'relative',
              }}
            >
              <Typography
                variant="h5"
                sx={{
                  padding: '5px',
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                {iconMap[storeCards.paths]} &nbsp; {storeCards.category}
              </Typography>

              <Button
                id={`btn_back_${index}`}
                name={`btn_back_${index}`}
                onClick={() => scroll(-100, index)}
                sx={{
                  position: 'absolute',
                  left: '10px',
                  top: '50%',
                  zIndex: 100,
                }}
              >
                <ArrowBackIosIcon />
              </Button>

              <Button
                id={`btn_next_${index}`}
                name={`btn_next_${index}`}
                onClick={() => scroll(100, index)}
                sx={{
                  position: 'absolute',
                  right: '10px',
                  top: '50%',
                  zIndex: 100,
                }}
              >
                <ArrowForwardIosIcon />
              </Button>

              <Box
                sx={{
                  position: 'relative',
                  width: '100%',
                  height: '300px',
                }}
              >
                <Box
                  id={`scroll_${index}`}
                  name={`scroll_${index}`}
                  ref={scrollRefs[index]}
                  sx={{
                    display: 'flex',
                    overflowX: 'auto',
                    scrollbarWidth: 'none',
                    '&::-webkit-scrollbar': {
                      display: 'none',
                    },
                    width: 'calc(100% - 96px)',
                    position: 'absolute',
                    left: 48,
                  }}
                >
                  {cards &&
                    cards
                      .filter((card) => card.idCat === storeCards.idCat)
                      .map((card) => (
                        <>
                          <Box display="flex" key={card.idCat}>
                            {' '}
                            {card.idCard && (
                              <Box p={1}>
                                <Card
                                  sx={{
                                    width: '250px',
                                    height: '300px',
                                  }}
                                >
                                  <CardActionArea
                                    onClick={() =>
                                      navigate('/pages/?id=' + card.idCard)
                                    }
                                    sx={{ zIndex: 90 }}
                                    title="Clique duplo para acessar o site"
                                  >
                                    <CardMedia
                                      component="img"
                                      height="140"
                                      image={`../images${card.site}/home.jpg`}
                                      alt="Busca Sorocaba"
                                    />
                                    <CardContent>
                                      <Typography variant="h6" component="div">
                                        {card.loja.length > 20
                                          ? card.loja.substring(0, 20) + '...'
                                          : card.loja}
                                      </Typography>
                                      <Typography
                                        variant="body2"
                                        color="text.secondary"
                                      >
                                        {card.descricao.length > 50
                                          ? card.descricao.substring(0, 50) +
                                            '...'
                                          : card.descricao}
                                      </Typography>
                                      <Typography variant="h7" component="div">
                                        Fone {card.fone}
                                      </Typography>
                                    </CardContent>
                                  </CardActionArea>
                                </Card>
                              </Box>
                            )}
                          </Box>
                        </>
                      ))}

                  {!trueStore && (
                    <Box p={1}>
                      <Card
                        sx={{
                          width: '250px',
                          height: '300px',
                        }}
                      >
                        <CardActionArea>
                          <Skeleton
                            variant="rectangular"
                            width="99vh"
                            height="140px"
                          />
                          <CardContent>
                            <Typography variant="h6" component="div">
                              <Skeleton
                                variant="rectangular"
                                width="100%"
                                height="25px"
                              />
                            </Typography>
                            <Typography variant="body2" color="text.secondary">
                              <Skeleton
                                variant="rectangular"
                                width="100%"
                                height="25px"
                                sx={{ marginTop: '5px' }}
                              />
                            </Typography>
                            <Typography variant="h7" component="div">
                              <Skeleton
                                variant="rectangular"
                                width="100%"
                                height="15px"
                                sx={{ marginTop: '5px' }}
                              />
                            </Typography>
                          </CardContent>
                        </CardActionArea>
                      </Card>
                    </Box>
                  )}
                </Box>
              </Box>
            </Box>
          ))}
    </Container>
  );
};

export default Cards;
