import React from 'react';

import { Box, Grid, List, ListItem, ListItemText } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { useNavigate } from 'react-router-dom';

const FooterStore = () => {
  const navigate = useNavigate();

  return (
    <Box style={{ borderTop: '2px solid #fff  ' }}>
      <Box
        width="80%"
        sx={{
          color: '#000',
          marginLeft: 'auto',
          marginRight: 'auto',
          paddingTop: '20px',
        }}
      >
        <Grid container spacing={2} justifyContent="center" alignItems="center">
          <Grid item xs={4}>
            <List>
              <ListItem>
                <ListItemText
                  onClick={() => navigate('/termos')}
                  primary="Como inserir meu cartão no portal?"
                  style={{ textAlign: 'center', cursor: 'pointer' }}
                />
              </ListItem>
              <ListItem>
                <ListItemText
                  onClick={() => navigate('/termos')}
                  primary="Como o site é mantido visto que é grátis?"
                  style={{ textAlign: 'center', cursor: 'pointer' }}
                />
              </ListItem>
              {/* Adicione mais itens conforme necessário */}
            </List>
          </Grid>
          <Grid item xs={4}>
            <List>
              <ListItem>
                <ListItemText
                  onClick={() => navigate('/termos')}
                  primary="Quero fazer uma doação para manter o site"
                  style={{ textAlign: 'center', cursor: 'pointer' }}
                />
              </ListItem>
              <ListItem>
                <ListItemText
                  onClick={() => navigate('/termos')}
                  primary="Quem pode anúnciar aqui?"
                  style={{ textAlign: 'center', cursor: 'pointer' }}
                />
              </ListItem>
              {/* Adicione mais itens conforme necessário */}
            </List>
          </Grid>
          <Grid item xs={4}>
            <List>
              <ListItem>
                <ListItemText
                  onClick={() => navigate('/termos')}
                  primary="Entre em contato conosco"
                  style={{ textAlign: 'center', cursor: 'pointer' }}
                />
              </ListItem>
              <ListItem>
                <ListItemText
                  onClick={() => navigate('/termos')}
                  primary="Termos de uso e política de privacidade"
                  style={{ textAlign: 'center', cursor: 'pointer' }}
                />
              </ListItem>
              {/* Adicione mais itens conforme necessário */}
            </List>
          </Grid>
        </Grid>
      </Box>
      <Box
        alignItems="center"
        textAlign="center"
        style={{ color: '#000', padding: '20px' }}
      >
        <Box
          display="flex"
          justifyItems="center"
          alignItems="center"
          textAlign="center"
          width="90%"
          style={{
            color: '#fff',
            padding: '30px',
            lineHeight: '2',
            letterSpacing: '1px',
            borderTop: '1px solid #fff',
            borderBottom: '1px solid #fff',
            marginBottom: '20px',
            marginTop: '20px',
            marginLeft: '3%',
          }}
        >
          <b style={{ paddingLeft: '10%', paddingRight: '10%' }}>
            Este site oferece um serviço gratuito de busca onde os usuários
            podem publicar suas próprias informações. Não nos responsabilizamos
            pelo conteúdo publicado pelos usuários e incentivamos a
            responsabilidade individual pela precisão e legalidade das
            postagens.
          </b>
        </Box>
        <b style={{ color: '#000' }}>
          {' '}
          <SearchIcon
            style={{
              fontSize: 20,
              verticalAlign: 'baseline',
              position: 'relative',
              top: '4px',
            }}
          />{' '}
          BuscaSorocaba{' '}
        </b>{' '}
        <span style={{ color: '#000' }}>
          {' '}
          - Site de busca da cidade de Sorocaba e região.
        </span>{' '}
        Anuncie grátis:
        <b>
          {' '}
          <a href="buscasorocaba@storecard.com.br" style={{ color: '#000' }}>
            buscasorocaba@storecard.com.br
          </a>{' '}
        </b>
      </Box>{' '}
    </Box>
  );
};

export default FooterStore;
