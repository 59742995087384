import create from 'zustand';

const useStore = create((set) => ({
  menus: null,
  cards: null,
  loading: true,
  error: null,
  setMenus: (menus) => set({ menus, loading: false, error: null }),
  setCards: (cards) => set({ cards, loading: false, error: null }),
  setError: (error) => set({ error, loading: false }),
}));

export default useStore;
